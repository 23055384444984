@import '../styles/variables.scss';

.Carousel {
    ul {
        padding: 0;
        display: flex;         // Make items line up in a row
        overflow-x: scroll;
        li {
            display: inline-block;

            img {
                width: 300px;
            }
        }
    }
}
