.download-buttons {
    @media (max-width: 768px) {
      text-align: center;
    }

    .dl-btn {
      border: none;
      background: none;
      cursor: pointer;
      color: #000 !important;
      display: inline-block;
      text-align: center;
      margin: 0 10px;

      img {
        margin-right: 5px;
        vertical-align: sub;
      }

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }

      &:hover {
        text-decoration: none;
        background-color: none;
      }
    }
}


