.Terms {
    max-width: 1200px;
    margin: 30px auto;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h1 {
        font-size: 25px;
        text-align: center;
    }

    h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    p {
        line-height: 1.5;
        text-align: justify;
    }

    table {
        border-collapse: collapse;
        text-align: left;
    }

    table, th, td {
        padding: 10px;
        border: 1px solid black;
    }
}