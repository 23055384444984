.App {
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
}

a:focus, a:active {
    text-decoration: none;
    color: inherit;
}