@import '../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000;
  color: white;

  .left-section, .center-section, .right-section {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .title {
    margin: 0;
  }

  .center-section {
    display: none;
    gap: 20px;
  }

  .right-section {
    gap: 10px;
  }

  .menu-button {
    background: none;
    border: none;
    font-size: 40px;
    color: white;
    cursor: pointer;
    display: none;
  }

  /* Media queries for mobile responsiveness */
  @media (min-width: $mobile-breakpoint) {
    .center-section {
      display: flex;
    }
    .menu-button {
      display: none;
    }
    .center-section {
        .download-buttons {
          display: none;
        }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .menu-button {
      display: block;
    }
    .right-section {
        .download-buttons {
          display: none;
        }
    }
    .center-section.open {
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      top: 70px;
      right: 0px;
      width: 230px;
      background-color: #000;
      padding: 15px;
    }
  }
}

