.NoMatch {
    padding: 20px;
    min-height: 500px;

    p {
        margin-bottom: 40px;
    }

    a {
        background-color: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px;
    }
}