@import '../styles/variables.scss';

.Home {
  .banner {
    height: 600px;
    background:
            linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), /* This is a black gradient with 50% opacity. Adjust as needed */
            url(~/public/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center; // Aligns horizontally
    align-items: center;

    .title {
        padding-top: 120px;
        margin: 0 10px;

        .main-title {
            border-bottom: 3px solid rgba(255, 189, 0, 1);
            padding-bottom: 20px;
            margin-bottom: 45px;

            h1 {
              font-size: 70px;
              line-height: 90px;
              margin: 20px 0;
              color: rgba(255, 189, 0, 1);

              @media (max-width: $mobile-breakpoint) {
                  font-size: 45px;
                  line-height: 65px;
              }
            }
        }

        .subtitle {
            text-align: center;
            color: #fff;
            font-size: 25px;
            font-style: normal;
            font-weight: bold;
            line-height: 30px;
        }
    }
  }

  .offer {
    display: none;
    padding: 30px 10px;
    background-color: #000;
    display: flex;
    justify-content: center; // Aligns horizontally
    align-items: center;

    .three {
        margin-right: 30px;
        width: 140px;

        @media (max-width: $mobile-breakpoint) {
            margin-right: 0;
        }
    }

    p {
      color: #FFF;
      margin: 10px;
      text-align: justify;
      leading-trim: both;

      font-family: Roboto;
      font-size: 70px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: $mobile-breakpoint) {
          text-align: center;
          font-size: 25px;
      }
    }
  }

  .time {
    padding: 60px 10px;
    display: flex;
    justify-content: center; // Aligns horizontally
    align-items: center;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    img {
        height: 600px;
        margin-right: 100px;

        @media (max-width: $mobile-breakpoint) {
            margin-right: 0;
        }
    }

    .text {
        max-width: 50%;
        text-align: left;
        @media (max-width: $mobile-breakpoint) {
            max-width: 100%;
            margin: 20px;
        }

        .initial {
            strong {
                display: inline-block;
                text-align: center;
                width: 30px;
            }
        }
    }

    h3 {
        color: var(--black, #0A2F54);
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h4 {
        margin: 5px 0;
        color: var(--dark-grey, #0A2F54);
        font-family: Roboto;
        font-size: 30px;
        font-weight: 400;
        line-height: normal;
    }
  }

  .time-details {
    padding: 60px 10px;
    display: flex;
    justify-content: center; // Aligns horizontally
    align-items: top;
    gap: 70px;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    .time-details-section {
        text-align: center;

        max-width: 220px;

        @media (max-width: $mobile-breakpoint) {
            max-width: 100%;
        }

        img {
            width: 40px;
            height: 50px;
        }

        h3 {
            color: var(--black, var(--App-blue, #052147));
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: normal;
            line-height: normal;
        }
        p {
        color: var(--App-blue, #052147);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
        }
    }
  }

  .works {
    text-align: center;
    color: var(--black, #171740);
    font-size: 53px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 20px;
  }

  .vip {
    padding: 60px 10px;
    display: flex;
    justify-content: center; // Aligns horizontally
    align-items: center;
    gap: 100px;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    img {
        height: 600px;
        margin-right: 100px;

        @media (max-width: $mobile-breakpoint) {
            margin-right: 0;
        }
    }

    .vip-club {
        padding: 20px;
        border-radius: 16px;
        background: #FFF;
        width: 300px;

        /* shadow-1 */
        box-shadow: 0px 4px 24px 0px rgba(9, 8, 66, 0.04), 0px 2px 4px 0px rgba(9, 8, 66, 0.08);
        text-align: center;

        h3 {
            margin-top: 0;
            color: var(--App-blue, #052147);
            text-align: center;

            text-edge: cap;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .benefit {
            margin-bottom: 17px;

            &:last-of-type {
                margin-bottom: 30px;
            }

            img, p {
                display: inline-block;
                vertical-align: top;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            p {
                width: calc(100% - 30px);
                margin-top: 0;
                margin: 0;
                text-align: left;

                span {
                    font-size: 16px;
                    display: block;
                    margin-bottom: 9px;
                }
                color: var(--App-blue, #052147);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        a {
            margin-top: 30px;
            padding: 5px 10px;
            border-radius: 15.08px;
            background: #FFBD00;
            color: #0A2F54;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .money {
            margin-top: 20px;
            color: var(--App-blue, #052147);
            text-edge: cap;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
  }
}
