.contact-container {
    padding: 0 15px;
    min-height: 600px;

    .Contact {
        margin: 30px auto;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #e1e1e1;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        max-width: 400px;

        .contact-title {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .contact-link {
            text-decoration: none;
            color: #007BFF;
            font-size: 18px;
        }

        .contact-link:hover {
            text-decoration: underline;
        }

        .ct {
            margin-top: 30px;
        }

        img {
            width: 100%;
        }
    }
}