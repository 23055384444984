@import '../styles/variables.scss';

.social-links {
  border-top: 1px solid #000;
  text-align: center;
  height: 50px

  a {
    display: inline-block;
  }

  img {
    margin: 15px;
    width: 35px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #000;
  color: white;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
    align-items: center;
  }

  strong {
    margin: 20px 0;
    text-align: center;
  }

  .footer-links {
    text-align: center;

    a {
      color: white;
      text-decoration: none;
      display: inline-block;
      margin: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      a {
         width: 100%;
      }
    }
  }
}
